import { useLogger } from 'src/hooks/useLogger'

declare global {
  interface Window {
    dataLayer: {
      push: (args: DataLayerData) => void
    }
  }
}

interface GlobalDataLayerData {
  businessId?: string
  staffId?: string
}

interface DataLayerData extends GlobalDataLayerData {
  event?: string
  [key: string]: string | number | boolean | undefined
}

/**
 * A hook for pushing data to GTM data layer.
 */
export const useDataLayer = () => {
  const logger = useLogger()

  /**
   * Push data to the data layer for GTM.
   * https://developers.google.com/tag-platform/tag-manager/datalayer#persist_data_layer_variables
   */
  const push = (data: DataLayerData) => {
    logger.debug(() => `dataLayer.push(${JSON.stringify(data)})`)
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push(data)
  }

  /**
   * Push event to the data layer for GTM.
   * This is no different than manually using `push()` but it's a convenience
   * method for tracking events.
   * https://developers.google.com/tag-platform/tag-manager/datalayer#tag-manager
   */
  const pushEvent = (event: string, data: DataLayerData) => {
    const eventData = {
      event,
      ...data,
    }
    push(eventData)
  }

  return {
    push,
    pushEvent,
  }
}
