import { useOverlay } from 'australis'
import { useEffect } from 'react'
import IntakeInviteModal from 'src/blocks/Workflows/components/IntakeInviteModal'
import InviteToPortalModal from 'src/blocks/Workflows/components/InviteToPortalModal'
import { useWorkflowStore } from '../store'

/**
 * Initialise the Workflow Store globally.
 * This should be included once per app and at the highest level possible.
 */
const WorkflowStoreLoader = () => {
  const [overlayState] = useOverlay()
  const setOverlayState = useWorkflowStore((state) => state.setOverlayState)
  const closeWorkflow = useWorkflowStore((state) => state.closeWorkflow)

  useEffect(() => {
    setOverlayState(overlayState)
    if (overlayState.isOpen === false) {
      closeWorkflow()
    }
  }, [overlayState, setOverlayState, closeWorkflow])

  return (
    <>
      <InviteToPortalModal />
      <IntakeInviteModal />
    </>
  )
}

export default WorkflowStoreLoader
