import { CloudOffline, UserOnline } from '@carbon/icons-react'
import { Avatar, Item, MenuTrigger } from 'australis'
import Image from 'next/image'
import { Key, useEffect, useState } from 'react'
import { useAvailableFeatures } from 'src/hooks/useAvailableFeatures'
import { useBusinessRouter } from 'src/hooks/useBusinessRouter'
import { ChannelStatus, useRealtime } from 'src/hooks/useRealtime'

interface UserMenuItem {
  key: string
  label: string
  avatarUrl?: string
}

interface PusherMember {
  id: string
  info: {
    staffId: string
    staffName: string
    staffAvatar: string
  }
}

/**
 * Show the list of currently online staff members.
 */
export const OnlineStaffMenu = () => {
  const businessRouter = useBusinessRouter()
  const availableFeatures = useAvailableFeatures()
  const realtime = useRealtime()
  const [onlineStaff, setOnlineStaff] = useState<Array<PusherMember>>([])

  useEffect(() => {
    const members: PusherMember[] = []
    realtime.businessChannel?.members.each((member: PusherMember) => {
      members.push(member)
    })
    setOnlineStaff(members)
  }, [realtime.businessChannel?.members.count])

  const items: Array<UserMenuItem> = onlineStaff.map((member) => ({
    key: member.info.staffId,
    label: member.info.staffName,
    avatarUrl: member.info.staffAvatar,
  }))

  const gotoStaff = (id: string) => {
    businessRouter.push(`/staff/${id}`)
  }

  if (!availableFeatures['realtime.ui']) return null

  return (
    <MenuTrigger
      badge={onlineStaff.length || null}
      variant='outline'
      iconButtonColor={
        realtime.businessChannelStatus === ChannelStatus.Connected
          ? 'success'
          : 'danger'
      }
      label={
        <>
          {realtime.businessChannelStatus === ChannelStatus.Connected ? (
            <UserOnline />
          ) : (
            <CloudOffline />
          )}
        </>
      }
      onAction={(id: Key) => gotoStaff(id.toString())}
      items={items}
    >
      {(item) => (
        <Item key={item.key} textValue={item.label}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <span style={{ marginRight: '0.5rem' }}>
              <Avatar name={item.label} size='sm'>
                {item.avatarUrl && (
                  <Image
                    width={24}
                    height={24}
                    src={item.avatarUrl}
                    alt={`${item.label} profile picture`}
                  />
                )}
              </Avatar>
            </span>
            <span>{item.label}</span>
          </div>
        </Item>
      )}
    </MenuTrigger>
  )
}
