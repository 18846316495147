import { HelpDesk } from '@carbon/icons-react'
import { IconButton } from 'australis'
import { useRouter } from 'next/router'

import { useCurrentBusinessId } from 'src/hooks/useCurrentBusiness'
import { useCurrentStaff } from 'src/hooks/useCurrentStaff'
import { StaffRole } from 'src/types/graphql'

/**
 * Shown to Upvio Support staff to indicate this is a supported business.
 * When clicked it will link back to the business edit area in the admin panel.
 */
const HelpDeskButton = () => {
  const currentStaff = useCurrentStaff()
  const currentBusinessId = useCurrentBusinessId()
  const router = useRouter()

  if (!currentStaff?.roles.includes(StaffRole.Support)) {
    return null
  }

  return (
    <IconButton
      color='warning'
      size='md'
      aria-label='View business in admin panel'
      onPress={() =>
        router.push(`/admin#/businesses/${currentBusinessId}/show`)
      }
    >
      <HelpDesk size={24} />
    </IconButton>
  )
}

export default HelpDeskButton
