import { Calendar, UserMultiple, VideoChat } from '@carbon/icons-react'
import { Item, ToolBar } from 'australis'
import styles from './MenuBar.module.scss'

export const data = [
  {
    icon: <Calendar size={22} />,
    label: 'Calendar',
    path: '/calendar',
  },
  {
    icon: <VideoChat size={22} />,
    label: 'Telehealth',
    path: '/telehealth',
  },
  {
    icon: <UserMultiple size={22} />,
    label: 'Patients',
    path: '/patients',
  },
]

export interface MenuBarProps {
  onChange: (key: string) => void
}

/**
 *

 * The MenuBar component is a navigation component that is used
 * in the mobile view to navigate between different pages of the application.
 * @example
 * <MenuBar onChange={(key) => console.log(key)} />
 */
const MenuBar: React.FC<MenuBarProps> = (props) => {
  const { onChange } = props

  return (
    <nav className={styles.MenuBar} data-testid='menubar'>
      <ToolBar
        items={data}
        selectionMode='single'
        disallowEmptySelection={true}
        onAction={(path) => onChange(path.toString())}
        aria-label='App Navigation'
      >
        {(item) => (
          <Item key={item.path} textValue={item.path} aria-label={item.label}>
            {item.icon}
            {item.label}
          </Item>
        )}
      </ToolBar>
    </nav>
  )
}

export default MenuBar
