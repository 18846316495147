import { ArrowUpRight } from '@carbon/icons-react'
import { Button, Flex, Heading, Page, Text } from 'australis'
import Image from 'next/image'
import { useRouter } from 'next/router'
import { useBreakpoint } from 'src/hooks/useBreakpoint'

type NotFoundPageProps = {
  action: {
    /** Text to show in the button */
    label: string
    url: string
  }
  title?: string
  description?: string
}

const DEFAULTS = {
  title: 'Oops! Page not found...',
  description:
    'We could not find the page you are looking for. It might have been moved or doesn’t exist anymore.',
}
const NotFoundPage: React.FC<NotFoundPageProps> = (props) => {
  const router = useRouter()
  const breakpoints = useBreakpoint()
  const {
    title = DEFAULTS.title,
    description = DEFAULTS.description,
    action,
  } = props

  return (
    <Page maxWidth='50rem'>
      <Page.Body
        direction='column'
        gap={['xs'].includes(breakpoints) ? '0px' : '3.5rem'}
        justifyContent='center'
      >
        <Image
          src='/static/assets/404.svg'
          alt='404'
          priority
          width={782}
          height={298}
        />

        <Flex
          gap='1rem'
          justifyContent='space-between'
          direction={{
            base: 'column',
            S: 'row',
          }}
        >
          <Flex
            direction='column'
            gap='0.75rem'
            maxWidth={{
              base: 'none',
              S: '70%',
            }}
          >
            <Heading type='semibold' size='xs'>
              {title}
            </Heading>

            <Text size='md'>{description}</Text>
          </Flex>

          <Button
            onPress={() => {
              router.push(action.url)
            }}
            icon={{
              icon: <ArrowUpRight />,
              position: 'end',
            }}
          >
            {action.label}
          </Button>
        </Flex>
      </Page.Body>
    </Page>
  )
}

export default NotFoundPage
