import { Link } from '@carbon/icons-react'
import { zodResolver } from '@hookform/resolvers/zod'
import {
  AttachmentList,
  Button,
  Input,
  TextArea,
  Modal,
  Text,
  useToast,
  useOverlay,
  Field,
} from 'australis'
import { useEffect, useState } from 'react'
import { Controller, useForm, FormProvider } from 'react-hook-form'
import { z } from 'zod'
import { FormPicker } from 'src/blocks/Pickers/FormPicker'
import { useWorkflow } from 'src/blocks/Workflows/hooks/useWorkflow'
import { useCurrentBusiness } from 'src/hooks/useCurrentBusiness'
import { useSendPatientIntakeInviteMutation } from 'src/types/graphql'

const IntakeInviteInput = z.object({
  subject: z.string().min(1, { message: 'Subject is required' }),
  body: z.string().min(1, { message: 'Body is required' }),
  formAttachments: z
    .array(z.string())
    .min(1, { message: 'Please attach at least one form' }),
})

type IntakeInviteInputType = z.infer<typeof IntakeInviteInput>

const IntakeInviteModal: React.FC<{ onExit?: () => void }> = ({ onExit }) => {
  const [fallbackOverlayState] = useOverlay()
  const [pickerOverlayState] = useOverlay()
  const [selectedForms, setSelectedForms] = useState<Map<string, string>>(
    new Map(),
  )
  const [loading, setLoading] = useState(false)
  const { currentBusiness } = useCurrentBusiness()
  const { showToast } = useToast()
  const workflow = useWorkflow('intakeInvite')

  const [sendInvite] = useSendPatientIntakeInviteMutation()

  const methods = useForm<IntakeInviteInputType>({
    resolver: zodResolver(IntakeInviteInput),
    defaultValues: {
      subject: '',
      body: '',
      formAttachments: [],
    },
  })

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors, isSubmitting },
  } = methods

  const onSubmitHandler = async (data: IntakeInviteInputType) => {
    if (!workflow.data) {
      return
    }

    setLoading(true)

    sendInvite({
      variables: {
        patientId: workflow.data.patientId,
        subject: data.subject,
        body: data.body,
        formList: data.formAttachments,
      },
      onCompleted() {
        setLoading(false)
        showToast('Invite sent successfully', 'success')
        workflow.close()
      },
      onError() {
        setLoading(false)
        showToast('Failed to send invite', 'error')
      },
    })
  }

  const handleOnFormSelected = (val: { id: string; label: string }) => {
    setSelectedForms((prev) => {
      const newMap = new Map(prev).set(val.id, val.label)
      methods.setValue('formAttachments', Array.from(newMap.keys()), {
        shouldValidate: true,
      })
      return newMap
    })
  }

  const attachmentList = Array.from(selectedForms).map(([id, label]) => ({
    id,
    label,
  }))

  const onAttachementRemove = (id: string) => {
    setSelectedForms((prev) => {
      const newMap = new Map(prev)
      newMap.delete(id)
      methods.setValue('formAttachments', Array.from(newMap.keys()), {
        shouldValidate: true,
      })
      return newMap
    })
  }
  useEffect(() => {
    if (workflow.isOpen) {
      // TODO: template can be updated through settings
      reset({
        subject: `Welcome to ${currentBusiness?.name || 'Upvio'}`,
        body: `Hi ${workflow.data?.name}, \n\n${currentBusiness?.name || 'Upvio'} has invited you to complete these forms. \nPlease refer to the links below to get started. \n\nKind regards, \n\n${currentBusiness?.name || 'Upvio'}\n\n`,
        formAttachments: [],
      })
      setSelectedForms(new Map())
    }
  }, [workflow.data?.name, currentBusiness?.name, reset, workflow.isOpen])

  if (!workflow.isEnabled) {
    return null
  }

  return (
    <Modal
      state={workflow.overlayState || fallbackOverlayState}
      size='sm'
      isDismissable
      onExited={onExit}
    >
      <FormProvider {...methods}>
        <form
          onSubmit={(event) => {
            // Prevent submission of other forms in the page
            event.preventDefault()
            event.stopPropagation()
            handleSubmit(onSubmitHandler)(event)
          }}
          noValidate
        >
          <Modal.Header onClose={() => workflow.close()}>
            Send Intake to {workflow.data?.name || 'Client'}
          </Modal.Header>
          <Modal.Body>
            <Text variant='caption' elementType='span'>
              An intake email will be sent to{' '}
              {workflow.data?.name || 'your client'} asking them to fill out the
              attached forms.
            </Text>
            <Controller
              name='subject'
              control={control}
              render={({ field }) => {
                return (
                  <Input
                    {...field}
                    label='Subject'
                    isDisabled={isSubmitting}
                    isRequired={true}
                    errorMessage={errors.subject?.message ?? ''}
                    validationState={errors.subject ? 'invalid' : 'valid'}
                  />
                )
              }}
            />

            <Controller
              name='body'
              control={control}
              render={({ field }) => {
                return (
                  <TextArea
                    {...field}
                    UNSAFE_style={{
                      resize: 'vertical',
                    }}
                    label='Body'
                    isDisabled={isSubmitting}
                    isRequired={true}
                    errorMessage={errors.body?.message ?? ''}
                    isInvalid={Boolean(errors.body)}
                  />
                )
              }}
            />

            <Field
              label='Form Attachments'
              isRequired
              errorMessage={errors.formAttachments?.message}
              validationState={errors.formAttachments ? 'invalid' : 'valid'}
            >
              <AttachmentList
                emptyStateDescription='No attached forms'
                variant='compact'
                items={attachmentList}
                onRemove={onAttachementRemove}
              />
            </Field>
            <Button
              type='button'
              icon={{
                position: 'start',
                icon: <Link />,
              }}
              alignSelf='end'
              variant='outline'
              color='secondary'
              size='sm'
              onPress={() => {
                pickerOverlayState.open()
              }}
            >
              Add Form Attachment
            </Button>
          </Modal.Body>
          <Modal.Footer>
            <Button
              isFullWidth
              variant='outline'
              color='secondary'
              onPress={() => workflow.close()}
            >
              Cancel
            </Button>
            <Button
              isFullWidth
              type='submit'
              isLoading={isSubmitting || loading}
            >
              Send
            </Button>
          </Modal.Footer>
        </form>
      </FormProvider>

      <FormPicker
        state={pickerOverlayState}
        onFormSelected={handleOnFormSelected}
      />
    </Modal>
  )
}

export default IntakeInviteModal
