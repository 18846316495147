import { zodResolver } from '@hookform/resolvers/zod'
import { Button, Input, TextArea, Modal, Text, useToast } from 'australis'
import { useEffect, useState } from 'react'
import { Controller, useForm, FormProvider } from 'react-hook-form'
import z from 'zod'

import { useCurrentBusiness } from 'src/hooks/useCurrentBusiness'
import { useSendPortalInviteMutation } from 'src/types/graphql'

import { useWorkflow } from '../hooks/useWorkflow'

const InviteToPortalInput = z.object({
  subject: z.string().min(1, { message: 'Subject is required' }),
  body: z.string().min(1, { message: 'Body is required' }),
})

type InviteToPortalInputType = z.infer<typeof InviteToPortalInput>

/**
 * Invite the selected patient to use the client portal via email.
 */
const InviteToPortalModal: React.FC = () => {
  const [loading, setLoading] = useState(false)
  const { currentBusiness } = useCurrentBusiness()
  const { showToast } = useToast()
  const workflow = useWorkflow('inviteToPortal')
  const [sendInvite] = useSendPortalInviteMutation()

  const methods = useForm<InviteToPortalInputType>({
    resolver: zodResolver(InviteToPortalInput),
    defaultValues: {
      subject: '',
      body: '',
    },
  })

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors, isSubmitting },
  } = methods

  const onSubmitHandler = async (data: InviteToPortalInputType) => {
    if (!workflow.data) {
      return
    }

    setLoading(true)

    sendInvite({
      variables: {
        patientId: workflow.data.patientId,
        subject: data.subject,
        body: data.body,
      },
      onCompleted() {
        setLoading(false)
        showToast('Invite sent successfully', 'success')
        workflow.close()
      },
      onError() {
        setLoading(false)
      },
    })
  }

  useEffect(() => {
    if (workflow.isOpen) {
      // TODO: template can be updated through settings
      reset({
        subject: `Welcome to ${currentBusiness?.name || 'Portal'}`,
        body: `Hi ${workflow.data?.name}, \n\nPlease use the link below to sign in to your secure client portal. \n\nKind regards, \n\n${currentBusiness?.name || 'Management'}\n\n`,
      })
    }
  }, [workflow.data?.name, currentBusiness?.name, reset, workflow.isOpen])

  if (!workflow.overlayState || !workflow.data) {
    return null
  }

  if (!workflow.isEnabled) {
    return null
  }

  return (
    <Modal state={workflow.overlayState} size='sm' isDismissable>
      <FormProvider {...methods}>
        <form
          onSubmit={(event) => {
            // Prevent submission of other forms in the page
            event.preventDefault()
            event.stopPropagation()
            handleSubmit(onSubmitHandler)(event)
          }}
          noValidate
        >
          <Modal.Header onClose={() => workflow.close()}>
            Invite {workflow.data.name} to Portal
          </Modal.Header>
          <Modal.Body>
            <Text variant='caption' elementType='span'>
              An invite email will be sent to your client with a link to access
              their portal
            </Text>
            <Controller
              name='subject'
              control={control}
              render={({ field }) => {
                return (
                  <Input
                    {...field}
                    label='Subject'
                    isDisabled={isSubmitting}
                    isRequired={true}
                    errorMessage={errors.subject?.message ?? ''}
                    validationState={errors.subject ? 'invalid' : 'valid'}
                  />
                )
              }}
            />

            <Controller
              name='body'
              control={control}
              render={({ field }) => {
                return (
                  <TextArea
                    {...field}
                    UNSAFE_style={{
                      resize: 'vertical',
                    }}
                    label='Body'
                    isDisabled={isSubmitting}
                    isRequired={true}
                    errorMessage={errors.body?.message ?? ''}
                    isInvalid={Boolean(errors.body)}
                  />
                )
              }}
            />
          </Modal.Body>
          <Modal.Footer>
            <Button
              isFullWidth
              variant='outline'
              color='secondary'
              onPress={() => workflow.close()}
            >
              Cancel
            </Button>
            <Button
              isFullWidth
              type='submit'
              isLoading={isSubmitting || loading}
            >
              Send
            </Button>
          </Modal.Footer>
        </form>
      </FormProvider>
    </Modal>
  )
}

export default InviteToPortalModal
