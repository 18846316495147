import { SideBar } from 'australis'
import { useEffect } from 'react'
import { useMainMenuItems } from 'src/hooks/useMainMenuItems'

import styles from './SideBarNav.module.scss'

export interface SideBarNavProps {
  collapse?: boolean
}

/**
 * SideBar Navigation Component
 * @prop {function} onChange - Callback function to handle navigation selection change
 */
const SideBarNav: React.FC<SideBarNavProps> = (props) => {
  const { collapse } = props
  const { menuItems, currentActiveMenu } = useMainMenuItems()

  useEffect(() => {
    // fixes main calendar not refreshing layout on collapse change
    window.dispatchEvent(new Event('resize'))
  }, [collapse])

  return (
    <SideBar
      className={[styles.SideBarNav].join(' ')}
      items={menuItems}
      aria-label='App Navigation'
      isCollapsed={collapse}
      selectedKeys={[currentActiveMenu]}
      selectionMode='single'
    >
      {(item) => (
        <SideBar.Link
          key={item.path}
          href={item.path}
          textValue={item.label}
          data-id={item.id}
        >
          {item.icon}
          {item.label}
          {item.rightIcon}
        </SideBar.Link>
      )}
    </SideBar>
  )
}

export default SideBarNav
