import { Lightning } from '@carbon/icons-react'
import { Button } from 'australis'

import { useAvailableFeatures } from 'src/hooks/useAvailableFeatures'
import { useBusinessRouter } from 'src/hooks/useBusinessRouter'
import { useCurrentStaff } from 'src/hooks/useCurrentStaff'
import { useSubscription, SubscriptionStatus } from 'src/hooks/useSubscription'
import { StaffRole } from 'src/types/graphql'

/**
 * Shown when business is currently on a trial.
 */
const UpgradePlanButton: React.FC = () => {
  const currentStaff = useCurrentStaff()
  const availableFeatures = useAvailableFeatures()
  const businessRouter = useBusinessRouter()
  const { subscriptionStatus } = useSubscription()

  if (!currentStaff?.roles?.includes(StaffRole.Owner)) {
    return null
  }

  if (!availableFeatures['billing']) {
    return null
  }

  if (subscriptionStatus !== SubscriptionStatus.Trialing) {
    return null
  }

  return (
    <Button
      color='upgrade'
      size='md'
      aria-label='Upgrade Plan'
      icon={{
        position: 'start',
        icon: <Lightning />,
      }}
      onPress={() => businessRouter.push('/settings/billing/manage')}
    >
      Upgrade Plan
    </Button>
  )
}

export default UpgradePlanButton
