import {
  Analytics,
  Blog,
  Calendar,
  CloudUpload,
  Code,
  MessageQueue,
  Settings,
  UserMultiple,
  VideoChat,
} from '@carbon/icons-react'
import { AustralIcon, Badge } from 'australis'
import { useMemo } from 'react'
import { Features, useAvailableFeatures } from 'src/hooks/useAvailableFeatures'
import { useBusinessRouter } from 'src/hooks/useBusinessRouter'
import { useCurrentStaff } from 'src/hooks/useCurrentStaff'
import { StaffRole } from 'src/types/graphql'
import type { SideBarItem } from 'australis'

interface MainMenuItem extends SideBarItem {
  requiredRole?: StaffRole
  requiredFeature?: string
  omit?: boolean
  id?: string
}

interface GetMenuItemsOptions {
  features: Features
  unreadMessagesCount: number
}

const getMainMenuItems = ({
  features,
  unreadMessagesCount,
}: GetMenuItemsOptions): MainMenuItem[] => {
  return [
    {
      icon: <Calendar size={24} />,
      label: 'Calendar',
      path: '/calendar',
    },
    {
      icon: <UserMultiple size={24} />,
      label: 'Patients',
      path: '/patients',
      requiredRole: features['patients.list'] ? undefined : StaffRole.Admin,
    },
    {
      icon: <MessageQueue size={24} />,
      label: 'Inbox',
      rightIcon:
        unreadMessagesCount > 0 ? (
          <Badge variant='default' color='blue' size='xs'>
            {unreadMessagesCount} New
          </Badge>
        ) : undefined,
      path: '/inbox',
      omit: !features.secureMessaging,
    },
    {
      icon: <Blog size={24} />,
      label: 'Forms',
      path: '/forms',
      requiredRole: StaffRole.Admin,
      omit: !features.forms,
    },
    {
      icon: <VideoChat size={24} />,
      label: 'Video & Chat',
      path: '/telehealth',
      omit: !features.telehealth,
    },
    {
      icon: <AustralIcon variant='staff' size={24} />,
      label: 'Staff',
      path: '/staff',
    },
    {
      icon: <AustralIcon variant='services' size={24} />,
      label: 'Services',
      path: '/services',
      requiredRole: StaffRole.Admin,
    },
    {
      icon: <AustralIcon variant='locations' size={24} />,
      label: 'Locations',
      path: '/locations',
      requiredRole: StaffRole.Admin,
    },
    {
      icon: <Analytics size={24} />,
      label: 'Analytics',
      path: '/analytics',
      requiredRole: StaffRole.Admin,
      omit: !features['analytics'],
    },
    {
      icon: <Code size={24} />,
      label: 'Developers',
      path: '/developers/docs',
      requiredRole: StaffRole.Developer,
    },
    {
      icon: <CloudUpload size={24} />,
      label: 'Data Imports',
      path: '/data-imports',
      omit: !features['patients.imports'],
      requiredRole: StaffRole.Admin,
    },
    {
      icon: <Settings size={24} />,
      label: 'Settings',
      path: '/settings',
    },
  ]
}

/**
 * Returns the main menu items for the current business
 */
export const useMainMenuItems = () => {
  const staff = useCurrentStaff()
  const businessRouter = useBusinessRouter()
  const availableFeatures = useAvailableFeatures()

  const items = useMemo(() => {
    return getMainMenuItems({
      features: availableFeatures || {},
      unreadMessagesCount: staff?.unreadMessagesCount || 0,
    }).filter((item) => {
      if (item.omit) {
        return false
      }
      if (item.requiredRole) {
        return staff?.roles.includes(item.requiredRole)
      }
      return true
    })
  }, [availableFeatures, staff?.roles])

  // Return absolute paths for each menu item
  const itemsWithBusinessPrefixPath = items.map((item) => ({
    ...item,
    id: item.path.substring(item.path.lastIndexOf('/') + 1),
    path: businessRouter.getHref(item.path),
  }))

  const currentActiveMenu = useMemo(() => {
    const paths = itemsWithBusinessPrefixPath.map((item) => item.path)

    // Try to find exact match
    const exactMatch = paths.find((path) => businessRouter.asPath === path)
    if (exactMatch) return exactMatch

    // Score-based matching for subpaths
    const bestMatch = calculateBestMatchPath(paths, businessRouter.asPath)

    return bestMatch
  }, [itemsWithBusinessPrefixPath, businessRouter.asPath])

  return { menuItems: itemsWithBusinessPrefixPath, currentActiveMenu }
}

const calculateBestMatchPath = (
  menuItemPaths: string[],
  currentPath: string,
) => {
  let bestMatch = ''
  let bestMatchScore = 0

  const currentPathSegments = currentPath
    .split('/')
    .filter((segment) => segment !== '')

  menuItemPaths.forEach((path) => {
    let currentScore = 0
    const menuPathSegments = path.split('/').filter((segment) => segment !== '')

    const minLength = Math.min(
      menuPathSegments.length,
      currentPathSegments.length,
    )

    for (let i = 0; i < minLength; i++) {
      if (menuPathSegments[i] === currentPathSegments[i]) {
        currentScore += 1
      } else {
        break // exit loop if segments don't match
      }
    }

    if (currentScore > bestMatchScore) {
      bestMatchScore = currentScore
      bestMatch = path
    }
  })

  return bestMatch
}
