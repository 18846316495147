import { Picker } from 'australis'
import { useEffect } from 'react'
import { OverlayTriggerState } from 'react-stately'
import { useBusinessFormsQuery } from 'src/types/graphql'
import { usePickerPagination } from './hooks/usePickerPagination'

type FormItem = {
  id: string
  label: string
}

interface FormPickerProps {
  state: OverlayTriggerState
  onFormSelected?: (item: FormItem) => void
}

export const FormPicker: React.FC<FormPickerProps> = ({
  state,
  onFormSelected,
}) => {
  const {
    searchTerm,
    setSearchTerm,
    searchTermDebounced,
    skip,
    limit,
    currentPage,
    handlePageChange,
    reset,
  } = usePickerPagination()

  const {
    data: formData,
    loading,
    error,
  } = useBusinessFormsQuery({
    variables: {
      limit,
      skip,
      search: searchTermDebounced,
    },
    fetchPolicy: 'cache-and-network',
  })

  const forms = formData?.forms?.map((form) => ({
    id: form.id,
    title: form.title ?? '',
    description: form.description ?? undefined,
  }))

  const handleItemSelected = (item: { id: string; title: string }) => {
    onFormSelected?.({
      id: item.id,
      label: item.title,
    })
  }

  useEffect(() => {
    if (!state.isOpen && searchTerm !== '') {
      reset()
    }
  }, [state.isOpen, reset, searchTerm])

  return (
    <Picker
      state={state}
      title='Pick a Form'
      items={forms}
      loading={loading}
      error={error?.message}
      currentPage={currentPage}
      totalItems={formData?.count ?? 0}
      perPage={limit}
      searchTerm={searchTerm}
      onSearchTermChange={setSearchTerm}
      onPageChange={handlePageChange}
      onItemSelected={handleItemSelected}
      searchPlaceholder='Search Forms'
      onEmptyStateButtonClick={reset}
      emptyStateProps={{
        title: 'No results found',
        subtitle: 'No Forms found matching your filter',
        emptyStateButtonText: 'Clear Search',
      }}
    />
  )
}
