import { Flex } from 'australis'
import { ReactNode } from 'react'
import { useBusinessRouter } from 'src/hooks/useBusinessRouter'
import Header from '../../components/Layout/Mobile/Header/Header'
import MenuBar from '../../components/Layout/Mobile/MenuBar/MenuBar'

export interface BusinessMobileProps {
  children: ReactNode
}

const BusinessMobile: React.FC<BusinessMobileProps> = ({ children }) => {
  const businessRouter = useBusinessRouter()

  return (
    <>
      <Flex
        UNSAFE_style={{
          position: 'sticky',
          top: '0px',
          right: '0px',
          width: '100%',
          zIndex: '1000',
          height: '56px',
        }}
      >
        <Header />
      </Flex>
      <Flex
        elementType='main'
        UNSAFE_style={{
          height: 'calc(100dvh - 132px)',
        }}
      >
        {children}
      </Flex>
      <Flex
        elementType='footer'
        UNSAFE_style={{
          position: 'fixed',
          bottom: '0px',
          left: '0px',
          width: '100%',
          height: '76px',
          borderTop: '1px solid var(--gray-light-100)',
          backgroundColor: 'var(--white)',
          zIndex: '1001',
        }}
      >
        <MenuBar onChange={(path) => businessRouter.push(path)} />
      </Flex>
    </>
  )
}

export default BusinessMobile
