import { ErrorView } from 'australis'
import clsx from 'clsx'
import { ErrorBoundary } from 'next/dist/client/components/error-boundary'
import { ReactNode } from 'react'
import { useLocalStorage } from 'react-use'

import { APP_ENV } from 'src/utils/constants'
import Header from '../../components/Layout/Desktop/Header/Header'
import SideBarNav from '../../components/Layout/Desktop/SideBarNav/SideBarNav'

import styles from './styles.module.scss'

export interface BusinessDesktopProps {
  children: ReactNode
}

const BusinessDesktop: React.FC<BusinessDesktopProps> = ({ children }) => {
  // if session is prefered to local storage then use useSessionStorageState
  const [collapseState, setCollapseState] = useLocalStorage<boolean>(
    'sidebar-state',
    false,
  )

  const onMenuToggleHandler = () => {
    setCollapseState(!collapseState)
  }

  const containerClasses = clsx(styles.BusinessDesktop, {
    [styles['BusinessDesktop--sidebar-collapsed']]: collapseState,
  })

  return (
    <div className={containerClasses}>
      <Header
        className={styles.BusinessDesktop_header}
        onMenuToggle={onMenuToggleHandler}
      />
      <aside className={styles.BusinessDesktop_sidebar}>
        <SideBarNav collapse={collapseState} />
      </aside>
      <main className={styles.BusinessDesktop_container}>
        <ErrorBoundary
          errorComponent={(props) => <ErrorView {...props} env={APP_ENV} />}
        >
          {children}
        </ErrorBoundary>
      </main>
    </div>
  )
}

export default BusinessDesktop
