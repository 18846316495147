import { create } from 'zustand'

import type { OverlayTriggerState } from 'react-stately'

type InviteDataType = { patientId: string; name: string; email: string }
export type WorkflowDataMap = {
  inviteToPortal: InviteDataType
  intakeInvite: InviteDataType
}

type EmailOverlayState = {
  /**
   * The currently open workflow.
   */
  currentWorkflowId: keyof WorkflowDataMap | null

  /**
   * The data associated with the current workflow.
   */
  data: WorkflowDataMap[keyof WorkflowDataMap] | null

  /**
   * Controller for the overlay state.
   * This is shared between all workflows.
   */
  overlayState: OverlayTriggerState | null

  /**
   * Set the global overlay state.
   * This should be done once when the app is initialized.
   */
  setOverlayState: (overlayState: OverlayTriggerState) => void

  /**
   * Set the current workflow and open the overlay.
   */
  openWorkflow: (
    workflowId: keyof WorkflowDataMap,
    data: WorkflowDataMap[keyof WorkflowDataMap],
  ) => void

  /**
   * Close any open workflow and remove all data.
   */
  closeWorkflow: () => void
}

const NOT_INITIALIZED_ERROR_MESSAGE =
  '<WorkflowStoreLoader /> is not initialized.'

export const useWorkflowStore = create<EmailOverlayState>((set, get) => ({
  currentWorkflowId: null,
  data: null,
  overlayState: null,
  setOverlayState(overlayState) {
    set({ overlayState })
  },
  openWorkflow(workflowId, data) {
    const overlayState = get().overlayState
    if (overlayState === null) {
      console.error(NOT_INITIALIZED_ERROR_MESSAGE)

      return
    }
    set({ currentWorkflowId: workflowId, data })
    overlayState.open()
  },
  closeWorkflow() {
    set({
      currentWorkflowId: null,
      data: null,
    })
    get().overlayState?.close()
  },
}))
