import { useState } from 'react'
import { useDebounce } from 'react-use'

interface PickerPagination {
  skip: number
  limit: number
  currentPage: number
  reset: () => void
  handlePageChange: (page: number) => void
  searchTerm: string
  searchTermDebounced: string
  setSearchTerm: (searchTerm: string) => void
}

const DEFAULT_PAGE_SIZE = 4

export const usePickerPagination = (size?: number): PickerPagination => {
  const page = 1
  const perPage = size ?? DEFAULT_PAGE_SIZE
  const [currentPage, setCurrentPage] = useState<number>(page)
  const [searchTerm, setSearchTermState] = useState('')
  const [searchTermDebounced, setSearchTermDebounced] = useState('')

  useDebounce(
    () => {
      setSearchTermDebounced(searchTerm)
    },
    250,
    [searchTerm],
  )

  const reset = () => {
    setCurrentPage(1)
    setSearchTerm('')
  }

  const handlePageChange = (page: number) => {
    setCurrentPage(page)
  }

  const setSearchTerm = (searchTerm: string) => {
    setSearchTermState(searchTerm)
  }

  const limit = perPage
  const skip = searchTerm === '' ? (currentPage - 1) * limit : 0

  return {
    skip,
    limit,
    currentPage,
    reset,
    handlePageChange,
    searchTerm,
    searchTermDebounced,
    setSearchTerm,
  }
}
