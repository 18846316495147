import { VideoChat } from '@carbon/icons-react'
import { IconButton } from 'australis'
import React from 'react'
import {
  useTelehealthClient,
  useTelehealthSelfParticipant,
  useTelehealthStore,
} from 'src/blocks/Zoom/hooks/useTelehealthStore'
import { ZoomSessionState } from 'src/blocks/Zoom/types'
import { useAvailableFeatures } from 'src/hooks/useAvailableFeatures'
import { useBusinessRouter } from 'src/hooks/useBusinessRouter'

/**
 * When a practitioner is currently on a video call they can see the status
 * and quickly click to go back to the call at any time.
 */
const TelehealthStatus: React.FC = () => {
  const availableFeatures = useAvailableFeatures()
  const businessRouter = useBusinessRouter()
  const zoomClient = useTelehealthClient()
  const [telehealthAppointmentId, telehealthSessionState] = useTelehealthStore(
    (state) => [state.appointmentId, state.sessionState],
  )
  const telehealthSelf = useTelehealthSelfParticipant()
  const telehealthParticipants = useTelehealthStore(
    (state) => state.participants,
  )

  if (!availableFeatures['telehealth']) {
    return null
  }
  if (telehealthSessionState !== ZoomSessionState.Connected) {
    return null
  }

  return (
    <IconButton
      color={telehealthSelf?.bVideoOn ? 'success' : 'secondary'}
      variant='solid'
      size='md'
      aria-label='chat'
      UNSAFE_style={{
        opacity: zoomClient ? 1 : 0,
        transition: 'all .4s',
      }}
      badge={telehealthParticipants.length || undefined}
      onPress={() =>
        businessRouter.push(
          `/appointments/${telehealthAppointmentId}/telehealth`,
        )
      }
    >
      <VideoChat size={24} />
    </IconButton>
  )
}

export default TelehealthStatus
