import { useDeepCompareEffect } from 'react-use'

import { useBusinessRouter } from 'src/hooks/useBusinessRouter'
import { useCurrentBusiness } from 'src/hooks/useCurrentBusiness'
import { useCurrentStaff } from 'src/hooks/useCurrentStaff'
import { useUserGuiding } from 'src/hooks/useUserGuiding'
import { UserGuidingIdentity } from 'src/shims/userGuiding'

/**
 * https://help.userguiding.com/en/articles/2824058-enabling-user-identification#
 */
const UserGuidingIdentify: React.FC = () => {
  const userGuiding = useUserGuiding()
  const currentStaff = useCurrentStaff()
  const { currentBusiness } = useCurrentBusiness()
  const { baseUrl } = useBusinessRouter()

  const data: UserGuidingIdentity = {
    businessId: currentBusiness?.id || '',
    businessAlias: currentBusiness?.alias || '',
    staffId: currentStaff?.id || '',
    staffAlias: currentStaff?.alias || '',
    email: currentStaff?.email || '',
    isBusinessAdmin: currentStaff?.isBusinessAdmin || false,
    stripeProductId: currentStaff?.stripeProductIds?.[0],
    baseUrl,
  }

  useDeepCompareEffect(() => {
    if (!data.staffId || !data.businessId || !userGuiding.loaded) return

    console.debug('<UserGuiding.Identify />', {
      data,
      sdkAvailable: !!window.userGuiding,
    })
    userGuiding.setIdentity(data.staffId, data)
  }, [data, userGuiding.loaded])

  return null
}

export default UserGuidingIdentify
