import { useAvailableFeatures } from 'src/hooks/useAvailableFeatures'
import { useWorkflowStore, WorkflowDataMap } from '../store'

import type { OverlayTriggerState } from 'react-stately'

interface Workflow<
  K extends keyof WorkflowDataMap,
  T extends WorkflowDataMap[K],
> {
  /**
   * ID of the currently open workflow.
   */
  id: K

  /**
   * Data associated with the currently open workflow.
   */
  data: T | null

  /**
   * Raw state managed by react-stately.
   * Pass this to modals to control their show/hide behaviour.
   */
  overlayState: OverlayTriggerState | null

  /**
   * Open workflow with the provided data.
   */
  open: (data: T) => void

  /**
   * Close workflow modal and reset all data.
   */
  close: () => void

  /**
   * True if this workflow is active and the overlay is open.
   */
  isOpen: boolean

  /**
   * Whether the workflow is available in the current context.
   */
  isEnabled: boolean
}

/**
 * Manage a workflow modal trigger.
 * Exposes global control to access and modify the workflow from anywhere in the application.
 */
export const useWorkflow = <
  K extends keyof WorkflowDataMap,
  T extends WorkflowDataMap[K],
>(
  id: K,
): Workflow<K, T> => {
  const data = useWorkflowStore((state) =>
    state.currentWorkflowId === id ? state.data : null,
  ) as T | null
  const overlayState = useWorkflowStore((state) =>
    state.currentWorkflowId === id ? state.overlayState : null,
  )
  const openWorkflowStore = useWorkflowStore((state) => state.openWorkflow)
  const closeWorkflow = useWorkflowStore((state) => state.closeWorkflow)
  const availableFeatures = useAvailableFeatures()
  const isOpen = overlayState?.isOpen ?? false
  const isEnabled = availableFeatures[`workflows.${id}`] ? true : false

  const open = (data: T) => {
    openWorkflowStore(id, data)
  }

  const close = () => {
    closeWorkflow()
  }

  return {
    id,
    data,
    overlayState,
    open,
    close,
    isOpen,
    isEnabled,
  }
}
