import { useApiConfig } from '@upvio/next'
import Pusher, { PresenceChannel } from 'pusher-js'
import { memo, useEffect, useMemo } from 'react'

import { useAvailableFeatures } from 'src/hooks/useAvailableFeatures'
import {
  useCurrentBusiness,
  useCurrentBusinessRouteAlias,
} from 'src/hooks/useCurrentBusiness'
import {
  useRealtime,
  useRealtimeBind,
  ChannelStatus,
} from 'src/hooks/useRealtime'
import { useAuthStore } from 'src/store/authStore'

const RealtimeLoader: React.FC = () => {
  const config = useApiConfig()
  const { accessToken } = useAuthStore()
  const realtime = useRealtime()
  const businessAlias = useCurrentBusinessRouteAlias()
  const { currentBusiness } = useCurrentBusiness()
  const currentBusinessId = currentBusiness?.id
  const availableFeatures = useAvailableFeatures()

  const pusher = useMemo(() => {
    return new Pusher(config.pusher_app_key, {
      cluster: 'mt1',
      channelAuthorization: {
        endpoint: `https://api.${config.domain}/realtime/auth`,
        transport: 'ajax',
        headers: {
          Authorization: accessToken ? `Bearer ${accessToken}` : '',
          'X-Business-Alias': businessAlias,
        },
      },
    })
  }, [accessToken, businessAlias])

  // Connect to business channel
  useEffect(() => {
    if (!currentBusinessId) return
    if (!availableFeatures.realtime) return

    realtime.setBusinessChannelStatus(ChannelStatus.Connecting)
    const channel = pusher.subscribe(
      `presence-business-${currentBusinessId}`,
    ) as PresenceChannel
    realtime.setBusinessChannel(channel)

    return () => {
      channel.unsubscribe()
      realtime.setBusinessChannel(null)
    }
  }, [currentBusinessId])

  useRealtimeBind(
    realtime.businessChannel,
    'pusher:subscription_succeeded',
    () => {
      realtime.setBusinessChannelStatus(ChannelStatus.Connected)
    },
  )
  useRealtimeBind(
    realtime.businessChannel,
    'pusher:subscription_failed',
    () => {
      realtime.setBusinessChannelStatus(ChannelStatus.Disconnected)
    },
  )

  return null
}

RealtimeLoader.displayName = 'RealtimeLoader'

export default memo(RealtimeLoader)
