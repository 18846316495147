import { Notification } from '@carbon/icons-react'
import { IconButton } from 'australis'
import React from 'react'

import { useAvailableFeatures } from 'src/hooks/useAvailableFeatures'

const GlobalNotificationsButton: React.FC = () => {
  const availableFeatures = useAvailableFeatures()

  if (!availableFeatures['topbar.notifications']) {
    return null
  }

  return (
    <IconButton
      color='secondary'
      size='md'
      aria-label='Notifications'
      isDisabled
    >
      <Notification size={24} />
    </IconButton>
  )
}

export default GlobalNotificationsButton
