import { RefObject, useEffect, useRef } from 'react'
import { useMount } from 'react-use'
import { shallow } from 'zustand/shallow'
import { createWithEqualityFn } from 'zustand/traditional'

export const SUPPORTED_RESOLUTIONS = [180, 360, 540, 720] as const
export type SupportedResolution = (typeof SUPPORTED_RESOLUTIONS)[number]

const DEFAULT_ASPECT_RATIO = 4 / 3
const DEFAULT_RESOLUTION = 360
const TARGET_FPS = 30

interface ClonedCanvas {
  canvasRef: RefObject<HTMLCanvasElement>
  resolution: SupportedResolution
  width: number
  height: number
}

interface TelehealthCanvasStoreState {
  selfGlobalCanvas: HTMLCanvasElement | null
  selfAspectRatio: number
  selfResolution: number
  activeParticipantGlobalCanvas: HTMLCanvasElement | null
  activeParticipantAspectRatio: number
  activeParticipantResolution: number

  initCanvases: () => void
}

/**
 * State of the offscren global canvas that render optimised video states.
 */
export const useTelehealthCanvasStore =
  createWithEqualityFn<TelehealthCanvasStoreState>(
    (set, get) => ({
      selfGlobalCanvas: null,
      selfAspectRatio: DEFAULT_ASPECT_RATIO,
      selfResolution: DEFAULT_RESOLUTION,

      activeParticipantGlobalCanvas: null,
      activeParticipantAspectRatio: DEFAULT_ASPECT_RATIO,
      activeParticipantResolution: DEFAULT_RESOLUTION,

      /**
       * Call this when the VideoClient is first mounted to global state
       */
      initCanvases: () => {
        const DEBUG_DISPLAY_OFFSCREEN_VIEWPORTS = localStorage.getItem(
          'DEBUG_DISPLAY_OFFSCREEN_VIEWPORTS',
        )
          ? true
          : false
        const selfGlobalCanvas =
          get().selfGlobalCanvas || document.createElement('canvas')
        selfGlobalCanvas.style.position = 'fixed'
        selfGlobalCanvas.style.left = DEBUG_DISPLAY_OFFSCREEN_VIEWPORTS
          ? '0px'
          : '-10000px'
        selfGlobalCanvas.style.bottom = `${DEFAULT_RESOLUTION}px`
        selfGlobalCanvas.style.background = '#020617'
        selfGlobalCanvas.setAttribute(
          'width',
          Math.ceil(DEFAULT_RESOLUTION * DEFAULT_ASPECT_RATIO).toString(),
        )
        selfGlobalCanvas.setAttribute('height', DEFAULT_RESOLUTION.toString())
        document.body.appendChild(selfGlobalCanvas)

        const activeParticipantGlobalCanvas =
          get().activeParticipantGlobalCanvas ||
          document.createElement('canvas')
        activeParticipantGlobalCanvas.style.position = 'fixed'
        activeParticipantGlobalCanvas.style.left =
          DEBUG_DISPLAY_OFFSCREEN_VIEWPORTS ? '0px' : '-10000px'
        activeParticipantGlobalCanvas.style.bottom = '0'
        activeParticipantGlobalCanvas.style.background = '#020617'
        activeParticipantGlobalCanvas.setAttribute(
          'width',
          Math.ceil(DEFAULT_RESOLUTION * DEFAULT_ASPECT_RATIO).toString(),
        )
        activeParticipantGlobalCanvas.setAttribute(
          'height',
          DEFAULT_RESOLUTION.toString(),
        )
        document.body.appendChild(activeParticipantGlobalCanvas)

        set({
          selfGlobalCanvas,
          activeParticipantGlobalCanvas,
        })
      },
    }),
    shallow,
  )

export const useOffscreenCanvases = () => {
  const initCanvases = useTelehealthCanvasStore((state) => state.initCanvases)

  useMount(() => {
    initCanvases()
  })

  return null
}

/**
 * Render a local canvas clone of a participants video stream.
 *
 * @example
 *   const canvasRef = useClonedCanvas(canvas, 360)
 *   return <canvas ref={canvasRef} />
 */
export const useClonedCanvas = (
  canvas: HTMLCanvasElement | null,
  resolution: SupportedResolution,
): ClonedCanvas => {
  const width = Math.ceil(resolution * DEFAULT_ASPECT_RATIO)
  const height = resolution
  const clonedCanvasRef = useRef<HTMLCanvasElement>(null)
  const isVideoOn = true

  useEffect(() => {
    let lastRenderedAt = performance.now()
    let frame = 0
    let process = true
    let timer: NodeJS.Timeout | null = null
    const msPerFrame = Math.floor(1000 / TARGET_FPS)
    const cloneCanvas = async (delta: number) => {
      const elapsed = delta - lastRenderedAt
      // console.log('cloneCanvas', animationRef, delta, process)
      lastRenderedAt = delta
      if (!process) {
        // console.log('cloneCanvas()', animationRef, 'stopping frame render')
        return
      }
      const clonedCanvas = clonedCanvasRef.current
      if (!canvas || !clonedCanvas || !isVideoOn) return
      const ctx = clonedCanvas.getContext('2d', {
        alpha: false,
      })
      if (!ctx) return

      const sleepMs = Math.max(msPerFrame - elapsed, 0)
      // console.log('rendering', elapsed, '<', msPerFrame, '    ', sleepMs)

      ctx.drawImage(
        canvas,
        0,
        0,
        canvas.width,
        canvas.height,
        0,
        0,
        width,
        height,
      )

      timer = setTimeout(() => {
        // lastRenderedAt = performance.now()
        frame = window.requestAnimationFrame(cloneCanvas)
      }, sleepMs)
    }

    frame = window.requestAnimationFrame(cloneCanvas)
    return () => {
      process = false
      if (frame > 0) {
        window.cancelAnimationFrame(frame)
      }
      if (timer) {
        clearTimeout(timer)
      }
    }
  }, [width, height, canvas, clonedCanvasRef, isVideoOn])

  return {
    canvasRef: clonedCanvasRef,
    width,
    height,
    resolution,
  }
}

export const useClonedActiveParticipantCanvas = (
  resolution: SupportedResolution,
) => {
  const globalCanvas = useTelehealthCanvasStore(
    (state) => state.activeParticipantGlobalCanvas,
  )

  return useClonedCanvas(globalCanvas, resolution)
}

export const useClonedSelfCanvas = (resolution: SupportedResolution) => {
  const globalCanvas = useTelehealthCanvasStore(
    (state) => state.selfGlobalCanvas,
  )

  return useClonedCanvas(globalCanvas, resolution)
}
