import { Avatar, Flex } from 'australis'
import { useClonedActiveParticipantCanvas } from 'src/blocks/Zoom/hooks/useTelehealthCanvasStore'
import { useTelehealthStore } from 'src/blocks/Zoom/hooks/useTelehealthStore'
import { ZoomSessionState } from 'src/blocks/Zoom/types'
import { useBreakpoint } from 'src/hooks/useBreakpoint'
import { useBusinessRouter } from 'src/hooks/useBusinessRouter'

/**
 * The mini stream to show when someone is not in the main video room.
 */
const GlobalParticipantStream: React.FC = () => {
  const businessRouter = useBusinessRouter()
  const { canvasRef, width, height } = useClonedActiveParticipantCanvas(180)
  const [activeParticipant, sessionState] = useTelehealthStore((state) => [
    state.participants.find((p) => p.userId === state.activeParticipantId),
    state.sessionState,
  ])
  const isVideoOn = activeParticipant?.bVideoOn ? true : false
  const telehealthAppointmentId = useTelehealthStore(
    (state) => state.appointmentId,
  )
  const telehealthAppointmentUrl = `/appointments/${telehealthAppointmentId}/telehealth`
  const inMeetingRoom =
    telehealthAppointmentId &&
    businessRouter.asPath.includes(telehealthAppointmentUrl)
  const shouldDisplay =
    activeParticipant &&
    telehealthAppointmentId &&
    !inMeetingRoom &&
    sessionState === ZoomSessionState.Connected
      ? true
      : false

  const breakpoint = useBreakpoint()
  const isMobile = ['xs', 'sm'].includes(breakpoint)
  // if mobile raise video to account for the bottom nav bar on mobile
  const floatingVideoBottomPosition = isMobile ? '86px' : '10px'

  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
    <div
      style={{
        position: 'fixed',
        bottom: shouldDisplay
          ? floatingVideoBottomPosition
          : `-${height + 10}px`,
        right: shouldDisplay ? '10px' : `-${width}px`,
        zIndex: 9999,
        background: '#000',
        borderRadius: '1rem',
        border: '3px solid #fff',
        boxShadow: '0 0 10px rgba(0,0,0,0.5)',
        overflow: 'hidden',
        transition: 'all 1s cubic-bezier(0.68, -0.55, 0.265, 1.55)',
        cursor: 'pointer',
      }}
      onClick={() => businessRouter.push(telehealthAppointmentUrl)}
    >
      <canvas
        ref={canvasRef}
        width={width}
        height={height}
        style={{ zIndex: 1, background: '#000', opacity: isVideoOn ? 1 : 0 }}
      ></canvas>
      {!isVideoOn && (
        <Flex
          position='absolute'
          top={0}
          left={0}
          right={0}
          bottom={0}
          alignItems='center'
          justifyContent='center'
        >
          <Avatar name={activeParticipant?.displayName || 'Patient'} />
        </Flex>
      )}
    </div>
  )
}

export default GlobalParticipantStream
