import { TopBar, UpvioLogoMobile } from 'australis'
import { UserMenu } from 'src/components/Layout/Desktop/Header/UserMenu'

const Header: React.FC = () => {
  return (
    <TopBar isMobile>
      <TopBar.LeftContent>
        <UpvioLogoMobile />
      </TopBar.LeftContent>
      <TopBar.RightContent>
        <UserMenu />
      </TopBar.RightContent>
    </TopBar>
  )
}

export default Header
