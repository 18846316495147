import { Channel, PresenceChannel } from 'pusher-js'
import PrivateChannel from 'pusher-js/types/src/core/channels/private_channel'
import { useEffect } from 'react'
import { create } from 'zustand'
import { useLogger } from 'src/hooks/useLogger'

export enum ChannelStatus {
  Connecting = 'connecting',
  Connected = 'connected',
  Disconnected = 'disconnected',
}

interface RealtimeStoreState {
  personalChannel: PrivateChannel | null
  personalChannelStatus: ChannelStatus
  setPersonalChannel: (channel: PrivateChannel | null) => void
  setPersonalChannelStatus: (channelStatus: ChannelStatus) => void

  messages: string[]
  logMessage: (message: string) => void

  businessChannel: PresenceChannel | null
  businessChannelStatus: ChannelStatus
  setBusinessChannel: (channel: PresenceChannel | null) => void
  setBusinessChannelStatus: (channelStatus: ChannelStatus) => void
}

const useRealtimeStore = create<RealtimeStoreState>((set) => ({
  personalChannel: null,
  personalChannelStatus: ChannelStatus.Disconnected,
  setPersonalChannel: (channel) => set({ personalChannel: channel }),
  setPersonalChannelStatus: (channelStatus) =>
    set({ personalChannelStatus: channelStatus }),

  businessChannel: null,
  businessChannelStatus: ChannelStatus.Disconnected,
  setBusinessChannel: (channel) => set({ businessChannel: channel }),
  setBusinessChannelStatus: (channelStatus) =>
    set({ businessChannelStatus: channelStatus }),

  messages: [],
  logMessage: (message) =>
    set((state) => ({ messages: [...state.messages, message] })),
}))

/**
 * Listen for events on a specific channel.
 */
export const useRealtimeBind = (
  channel: Channel | null,
  eventNameOrNames: string | string[],
  handler: (data: any) => void,
) => {
  const logger = useLogger()
  const eventNames = Array.isArray(eventNameOrNames)
    ? eventNameOrNames
    : [eventNameOrNames]

  useEffect(() => {
    if (!channel) return

    eventNames.forEach((eventName) => {
      logger.debug(() => `useRealtimeBind() bind ${channel.name} ${eventName}`)
      channel?.bind(eventName, handler)
    })
    return () => {
      eventNames.forEach((eventName) => {
        logger.debug(
          () => `useRealtimeBind() unbind ${channel.name} ${eventName}`,
        )
        channel?.unbind(eventName, handler)
      })
    }
  }, [channel?.name, eventNames])
}

export const useRealtime = () => {
  return useRealtimeStore()
}
