import { Search } from '@carbon/icons-react'
import { IconButton } from 'australis'
import React from 'react'
import { useAvailableFeatures } from 'src/hooks/useAvailableFeatures'

/**
 * A button to quickly search the whole account from anywhere in the app.
 */
const GlobalSearchButton: React.FC = () => {
  const availableFeatures = useAvailableFeatures()

  if (!availableFeatures['topbar.search']) {
    return null
  }

  return (
    <IconButton color='secondary' size='md' aria-label='Search' isDisabled>
      <Search size={24} />
    </IconButton>
  )
}

export default GlobalSearchButton
