import { Flex, Heading, Text } from 'australis'
import Link from 'next/link'
import { ReactNode, useEffect } from 'react'
import NotFoundPage from 'src/blocks/ErrorPages/NotFound'
import WorkflowStoreLoader from 'src/blocks/Workflows/components/WorkflowStoreLoader'
import GlobalParticipantStream from 'src/blocks/Zoom/components/video/streams/GlobalParticipantStream'
import { useDataLayer } from 'src/components/GoogleTagManager/useDataLayer'
import Loading from 'src/components/Loading/Loading'
import PageTitle from 'src/components/PageTitle'
import RealtimeLoader from 'src/components/RealtimeLoader'
import UserGuidingIdentify from 'src/components/UserGuiding/Identify'
import { useAvailableFeatures } from 'src/hooks/useAvailableFeatures'
import { useBreakpoint } from 'src/hooks/useBreakpoint'
import { useBusinessRouter } from 'src/hooks/useBusinessRouter'
import { useCurrentBusiness } from 'src/hooks/useCurrentBusiness'
import { useCurrentStaffQuery } from 'src/hooks/useCurrentStaff'
import { useLogger } from 'src/hooks/useLogger'
import { useRealtime, useRealtimeBind } from 'src/hooks/useRealtime'
import { useSubscription, SubscriptionStatus } from 'src/hooks/useSubscription'
import BusinessDesktopLayout from '../BusinessDesktop/BusinessDesktop'
import BusinessMobileLayout from '../BusinessMobile/BusinessMobile'

import 'australis/dist/esm/foundation/index.scss'
import 'australis/dist/esm/foundation/themes.css'

interface BusinessLayoutProps {
  children: ReactNode

  /**
   * The page title inside the <title> tag.
   */
  title?: string
}

/**
 * This wraps all pages that need a current business context.
 * When the business ID changes, the business data will be re-fetched from the server to update state.
 */
const BusinessLayout: React.FC<BusinessLayoutProps> = ({ children, title }) => {
  const breakpoint = useBreakpoint()

  const {
    currentBusinessId,
    currentBusiness,
    loading: currentBusinessLoading,
    refetch: refetchCurrentBusiness,
    error: currentBusinessError,
  } = useCurrentBusiness()
  const {
    currentStaff,
    loading: currentStaffLoading,
    refetch: refetchCurrentStaff,
  } = useCurrentStaffQuery()
  const realtime = useRealtime()
  const currentStaffId = currentStaff?.id
  const dataLayer = useDataLayer()
  const logger = useLogger()
  const { remainingTrialDays, subscriptionStatus } = useSubscription()
  const { push, asRelativePath } = useBusinessRouter()
  const availableFeatures = useAvailableFeatures()
  const isBillingRestricted = !!availableFeatures['billing.restrictions']

  // Ensure GTM has the business and staff context when this layout is used.
  useEffect(() => {
    dataLayer.pushEvent('staffContextReady', {
      businessId: currentBusiness?.id,
      staffId: currentStaff?.id,
    })
  }, [currentBusiness?.id, currentStaff?.id, dataLayer, logger])

  useRealtimeBind(realtime.businessChannel, 'business.update', async () => {
    await refetchCurrentBusiness()
    await refetchCurrentStaff()
  })

  useRealtimeBind(realtime.businessChannel, 'staff.update', async (data) => {
    if (currentStaffId === data.staffId) {
      await refetchCurrentStaff()
    }
  })

  // If trial has expired, redirect to the trial over page
  useEffect(() => {
    if (currentBusinessLoading || currentStaffLoading) return
    if (!currentStaff?.id) return
    if (!isBillingRestricted) return
    if (subscriptionStatus === SubscriptionStatus.Active) return
    if (asRelativePath.indexOf('/trial') === 0) return
    if (asRelativePath.indexOf('/settings/billing') === 0) return
    if (remainingTrialDays !== null && remainingTrialDays > 0) return

    push(`/trial/expired`)
  }, [
    currentBusinessLoading,
    currentStaffLoading,
    currentStaff?.id,
    remainingTrialDays,
    subscriptionStatus,
    push,
    asRelativePath,
    isBillingRestricted,
  ])

  if (
    currentBusinessId === undefined ||
    (currentBusinessLoading && !currentBusiness) ||
    (currentStaffLoading && !currentStaff)
  ) {
    return <Loading fullScreen />
  }

  if (currentBusinessError?.message) {
    return (
      <Flex justifyContent='center' alignItems='center' height='100vh'>
        <div style={{ textAlign: 'center' }}>
          <Heading variant='subheading'>
            Error: {currentBusinessError.message}
          </Heading>
          <Text>
            <Link href='/businesses'>View all businesses</Link>
          </Text>
        </div>
      </Flex>
    )
  }

  if (!currentBusiness) {
    return (
      <NotFoundPage
        action={{
          label: 'See all businesses',
          url: '/businesses',
        }}
      />
    )
  }

  return (
    <>
      {title && <PageTitle>{title}</PageTitle>}
      {['md', 'lg', 'xl'].includes(breakpoint) ? (
        <BusinessDesktopLayout>{children}</BusinessDesktopLayout>
      ) : (
        <BusinessMobileLayout>{children}</BusinessMobileLayout>
      )}
      <RealtimeLoader />
      <GlobalParticipantStream />
      <UserGuidingIdentify />
      <WorkflowStoreLoader />
    </>
  )
}

export default BusinessLayout
