import { Menu } from '@carbon/icons-react'
import { IconButton, TopBar, UpvioLogoDesktop } from 'australis'
import BusinessName from 'src/components/Layout/Desktop/Header/BusinessName'
import GlobalNotificationsButton from 'src/components/Layout/Desktop/Header/GlobalNotificationsButton'
import GlobalSearchButton from 'src/components/Layout/Desktop/Header/GlobalSearchButton'
import HelpDeskButton from 'src/components/Layout/Desktop/Header/HelpDeskButton'
import { OnlineStaffMenu } from 'src/components/Layout/Desktop/Header/OnlineStaffMenu'
import TelehealthStatus from 'src/components/Layout/Desktop/Header/TelehealthStatus'
import UpgradePlanButton from 'src/components/Layout/Desktop/Header/UpgradePlanButton'
import { UserMenu } from 'src/components/Layout/Desktop/Header/UserMenu'
import TrialBanner from 'src/components/TrialBanner/TrialBanner'

export interface HeaderProps {
  onMenuToggle?: () => void
  className?: string
}

const Header: React.FC<HeaderProps> = (props) => {
  const { onMenuToggle, className } = props

  return (
    <div className={className}>
      <TrialBanner />
      <TopBar>
        <TopBar.LeftContent>
          <IconButton
            variant='ghost'
            color='secondary'
            aria-label='Show/Hide Menu'
            onPress={onMenuToggle}
          >
            <Menu size={24} />
          </IconButton>
          <UpvioLogoDesktop />
          <BusinessName />
        </TopBar.LeftContent>
        <TopBar.RightContent>
          <TelehealthStatus />
          <HelpDeskButton />
          <UpgradePlanButton />
          <GlobalSearchButton />
          <OnlineStaffMenu />
          <GlobalNotificationsButton />
          <UserMenu />
        </TopBar.RightContent>
      </TopBar>
    </div>
  )
}

export default Header
